import React from "react";
import { func, bool, any } from "prop-types";
import { noop, get } from "lodash";
import AsyncDecorator from "async-decorator/rx6";
import { createDisposableModal } from "react-disposable-modal";
import { Scoped, always } from "kremling";
import { fromEvent } from "rxjs";
import { filter } from "rxjs/operators";
import auth from "cp-client-auth!sofe";
import { catchAsyncStacktrace } from "auto-trace";
import { DateTime } from "luxon";
import { CprButtonIcon, CprButton, CprIcon } from "canopy-styleguide!sofe";
import {
  isProductionRevision,
  isReleaseCandidate,
  isDraft,
  isLive,
} from "sme-version!sofe";

import VisibilityTogglesModal from "./visibility-toggles/visibility-toggles-modal.component";
import css from "src/versions2/versions-styles.krem.css";
import EditRevisionModal from "src/versions2/edit-revision-modal.component.js";
import { updateARevision } from "src/versions2/versions.resource.js";

@AsyncDecorator
export default class RevisionListRow extends React.Component {
  static propTypes = {
    cancelWhenUnmounted: func,
    revision: any,
    onCreateReleaseCandidateClick: func,
    onSetActive: func,
    onDeleteReleaseCandidate: func,
    onViewChanges: func,
    standardRevisionExists: bool,
    isNameUnique: func,
  };

  static defaultProps = {
    onCreateReleaseCandidateClick: noop,
    onSetActive: noop,
    onDeleteReleaseCandidate: noop,
    cancelWhenUnmounted: noop,
  };

  state = {
    kabobOpen: false,
    modalOpen: false,
    name: this.props.revision.name,
    description: this.props.revision.description,
    loggedInUser: null,
  };

  kabobRef = React.createRef();

  componentDidMount() {
    this.props.cancelWhenUnmounted(
      fromEvent(document, "click")
        .pipe(
          filter(
            (event) =>
              this.state.kabobOpen &&
              !this.kabobRef.current.contains(event.target)
          )
        )
        .subscribe(() => {
          this.setState({ kabobOpen: false });
        })
    );

    this.props.cancelWhenUnmounted(
      auth
        .getLoggedInUserAsObservable()
        .subscribe((loggedInUser) => this.setState({ loggedInUser }))
    );
  }

  render() {
    const { revision } = this.props;
    const { modalOpen, name, description } = this.state;

    return (
      <Scoped css={css}>
        <div
          className={always("list-row").maybe(
            "active-revision",
            isLive(revision)
          )}
        >
          <div className="name-column">
            <span title={name}>{name}</span>
          </div>
          {isProductionRevision(revision) && (
            <div className="status-and-type-column">
              {isLive(revision) ? "Active" : "Inactive"}
            </div>
          )}
          {isReleaseCandidate(revision) && (
            <div className="status-and-type-column">
              {revision.status === "releaseCandidateHotFix" ? (
                <b>Hot Fix</b>
              ) : (
                "Standard"
              )}
            </div>
          )}
          <div className="date-activated-column">
            {!isDraft(revision) &&
              DateTime.fromMillis(revision.creationDate).toLocaleString(
                DateTime.DATETIME_MED
              )}
          </div>
          <div className="description-column">
            {!isDraft(revision) && description}
          </div>
          <div className="actions-column">
            {((isProductionRevision(revision) && isLive(revision)) ||
              isDraft(revision)) && (
              <CprButton
                className="row-action-button"
                actionType="flat"
                disabled={this.props.standardRevisionExists}
                onClick={() => {
                  this.props.onCreateReleaseCandidateClick(
                    revision,
                    !isDraft(revision)
                  );
                }}
              >
                {isProductionRevision(revision)
                  ? "Create hot fix"
                  : "Create release candidate"}
              </CprButton>
            )}
            {isReleaseCandidate(revision) && (
              <CprButton
                className="row-action-button"
                actionType="primary"
                onClick={() => {
                  this.props.onSetActive(revision);
                }}
              >
                Set as active
              </CprButton>
            )}
            {this.getDropDownMenu(revision)}
          </div>
        </div>
        {modalOpen && (
          <EditRevisionModal
            name={name}
            description={description}
            revisionId={revision.id}
            onModalClose={() => {
              this.setState({ modalOpen: false });
            }}
            updateRevision={this.updateRevision}
            isNameUnique={this.props.isNameUnique}
          />
        )}
      </Scoped>
    );
  }

  getDropDownMenu = (revision) => {
    const showDeleteOption =
      isReleaseCandidate(revision) ||
      (isProductionRevision(revision) &&
        !isLive(revision) &&
        !isDraft(revision));

    const smePublishAccess = auth.hasAccess(this.state.loggedInUser)(
      "sme_publish"
    );

    return (
      <div
        className={always("cps-dropdown").maybe(
          "cps-open",
          this.state.kabobOpen
        )}
        ref={this.kabobRef}
      >
        <CprButtonIcon
          icon="misc-kabob"
          onClick={() => {
            this.setState((prevState) => ({
              kabobOpen: !prevState.kabobOpen,
            }));
          }}
          customClass="kabob-menu"
        />
        <ul className="cps-dropdown-menu cps-pull-right">
          <li>
            <CprButton
              onClick={() => {
                this.setState({ kabobOpen: false, modalOpen: true });
              }}
            >
              <CprIcon name="crud-pencil" /> Rename
            </CprButton>
          </li>
          <li>
            <CprButton
              onClick={() => {
                this.setState({ kabobOpen: false });
                this.props.onViewChanges(revision);
              }}
            >
              <CprIcon name="organize-sidebar" /> View changes
            </CprButton>
          </li>
          {showDeleteOption && (
            <li>
              <CprButton
                onClick={() => {
                  this.setState({ kabobOpen: false });
                  this.props.onDeleteReleaseCandidate(revision);
                }}
              >
                <CprIcon name="crud-trash-large" /> Delete
              </CprButton>
            </li>
          )}
          {isProductionRevision(revision) && !isLive(revision) && (
            <li>
              <div className="divider-line" />
              <CprButton
                onClick={() => {
                  this.setState({ kabobOpen: false });
                  this.props.onSetActive(revision);
                }}
              >
                <CprIcon name="checkmark-line-badge" /> Set as active
              </CprButton>
            </li>
          )}
          {smePublishAccess &&
            revision.product === "taxPrep" &&
            (isLive(revision) ||
              isReleaseCandidate(revision) ||
              isDraft(revision)) && (
              <li>
                <div className="divider-line" />
                <CprButton
                  onClick={() => {
                    this.showVisibilityToggles(revision);
                  }}
                >
                  <CprIcon name="checkmark-line-badge" /> Visbility toggles
                </CprButton>
              </li>
            )}
        </ul>
      </div>
    );
  };

  showVisibilityToggles = (revision) => {
    this.setState({ kabobOpen: false });

    this.props.cancelWhenUnmounted(
      createDisposableModal(VisibilityTogglesModal, {
        revision,
        cancelWhenUnmounted: this.props.cancelWhenUnmounted,
      }).subscribe(() => {}, catchAsyncStacktrace())
    );
  };

  updateRevision = (name, description) => {
    this.props.cancelWhenUnmounted(
      updateARevision(
        this.props.revision.version,
        this.props.revision.id,
        name,
        description
      ).subscribe((_) => {
        this.setState({
          modalOpen: false,
          name,
          description,
        });
      }, catchAsyncStacktrace())
    );
  };
}
