import React from "react";
import { Scoped, k } from "kremling";
import { string } from "prop-types";
import { catchSyncStacktrace } from "auto-trace";
import { findIndex, isEmpty } from "lodash";
import { CprLoader } from "canopy-styleguide!sofe";

import {
  getGovernmentCodeBlacklist,
  updateEfileSubTypeBlacklist,
} from "../versions.resource";

export default class SubmissionTypeToggles extends React.Component {
  static propTypes = {
    governmentCode: string,
    version: string,
  };

  state = {
    efileBlacklist: [],
    loading: false,
  };

  componentDidMount() {
    this.getBlackList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.governmentCode !== this.props.governmentCode) {
      this.getBlackList();
    }
  }

  getBlackList = () => {
    this.setState({ loading: true });
    this.props.cancelWhenUnmounted(
      getGovernmentCodeBlacklist(
        this.props.governmentCode,
        this.props.version
      ).subscribe(
        (blacklist) => {
          this.setState({
            efileBlacklist: blacklist.return_categories,
            loading: false,
          });
        },
        (err) => {
          this.setState({ loading: false });
          catchSyncStacktrace(err);
        }
      )
    );
  };

  updateEfile = (efile, submissionType, returnType) => {
    const { efileBlacklist } = this.state;
    const returnTypeIndex = findIndex(efileBlacklist, (category) => {
      return category.return_category === returnType;
    });
    const submissionTypeIndex = findIndex(
      efileBlacklist[returnTypeIndex].submission_types,
      (subType) => {
        return subType.submission_type === submissionType;
      }
    );
    let updatedBlacklist = efileBlacklist.slice();

    updatedBlacklist[returnTypeIndex].submission_types[
      submissionTypeIndex
    ].efile = efile;

    // optimistically set state while we wait for an api response
    this.setState({ efileBlacklist: updatedBlacklist });

    this.props.cancelWhenUnmounted(
      updateEfileSubTypeBlacklist(
        this.props.governmentCode,
        this.props.version,
        submissionType,
        !efile
      ).subscribe(
        (blacklist) => {
          this.setState({ efileBlacklist: blacklist.return_categories });
        },
        (err) => {
          // if there is an error, set blacklist back to how it was
          updatedBlacklist[returnTypeIndex].submission_types[
            submissionTypeIndex
          ].efile = !efile;
          this.setState({ efileBlacklist: updatedBlacklist });
          catchSyncStacktrace(err);
        }
      )
    );
  };

  renderBlacklistColumn = (columnList) => {
    return columnList.map((card) => {
      return (
        <div className="st-return-type-card" key={card.return_category}>
          <div className="st-return-type-title">{card.return_category}</div>
          <div className="st-toggle-row st-headers">
            <div>Submission Type</div>
            <div>E-File</div>
          </div>
          {card.submission_types.map((item) => {
            return (
              <div
                className="st-toggle-row"
                key={`${card.return_category}-${item.submission_type}`}
              >
                <div>{item.submission_type}</div>
                <div>
                  <form>
                    <label className="cps-toggle">
                      <input
                        checked={item.efile}
                        type="checkbox"
                        onChange={(e) =>
                          this.updateEfile(
                            e.target.checked,
                            item.submission_type,
                            card.return_category
                          )
                        }
                      />
                      <span />
                    </label>
                  </form>
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  };

  render() {
    const { efileBlacklist, loading } = this.state;
    const halfLength = Math.ceil(efileBlacklist.length / 2);
    const leftColumnCards = efileBlacklist.slice(0, halfLength);
    const rightColumnCards = efileBlacklist.slice(halfLength);

    return (
      <Scoped css={css}>
        <div className="submission-type-toggles">
          {isEmpty(efileBlacklist) && !loading ? (
            <div className="empty-blacklist">
              <img
                src="https://cdn.canopytax.com/static/empty-states/es_billing_bank_building.svg"
                style={{ height: "90px", marginBottom: "24px" }}
              />
              <div style={{ fontSize: "24px", fontWeight: "bold" }}>
                No submission types
              </div>
              <div style={{ fontSize: "16px" }}>
                There are no submission types for this government code
              </div>
            </div>
          ) : loading ? (
            <CprLoader
              className="blacklist-loader"
              dotSize={16}
              color="var(--cps-color-primary)"
            />
          ) : (
            <>
              <div className="st-type-cards-left">
                {this.renderBlacklistColumn(leftColumnCards)}
              </div>
              <div className="st-type-cards-right">
                {this.renderBlacklistColumn(rightColumnCards)}
              </div>
            </>
          )}
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .submission-type-toggles {
    display: flex;
    overflow: scroll;
    width: 800px;
    height: 750px;
    padding: 24px 0 0 16px;
    border-top: 1px solid var(--cps-color-athens);
    border-right: 1px solid var(--cps-color-athens);
    border-bottom: 1px solid var(--cps-color-athens);
    border-radius: 0 4px 4px 0;
  }

  .empty-blacklist {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 14rem;
  }

  .st-type-cards-left {
    display: flex;
    flex-direction: column;
  }

  .st-type-cards-right {
    display: flex;
    flex-direction: column;
  }

  .st-type-cards-right .st-return-type-card {
    margin-right: 16px;
  }

  .st-return-type-card {
    height: fit-content;
    padding-bottom: 16px;
    margin: 0 24px 24px 0;
    width: 363px;
    border-radius: 4px;
    border: 1px solid var(--cps-color-athens);
  }

  .st-return-type-title {
    display: flex;
    align-items: center;
    height: 52px;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid var(--cps-color-silver);
    padding-left: 16px;
  }

  .st-toggle-row {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
  }

  .st-headers {
    color: var(--cps-color-af);
    font-size: 14px;
    font-weight: bold;
  }

  .blacklist-loader {
    margin: 30% auto;
  }
`;
