import React from "react";
import { string, func } from "prop-types";
import { Scoped, always } from "kremling";
import { CpModal, CpButton } from "canopy-styleguide!sofe";

import css from "src/versions2/versions-styles.krem.css";

export default class EditRevisionModal extends React.Component {
  static propTypes = {
    name: string,
    description: string,
    revisionId: string,
    onModalClose: func,
    updateRevision: func,
    isNameUnique: func,
  };

  state = {
    name: this.props.name,
    description: this.props.description,
  };

  render() {
    const showNameError =
      !this.props.isNameUnique(this.state.name, this.props.revisionId) &&
      this.props.name !== this.state.name;

    return (
      <CpModal show onClose={this.props.onModalClose}>
        <CpModal.Header title="Edit" />
        <CpModal.Body>
          <Scoped css={css}>
            <div
              className={always("cps-form-group").maybe(
                "cps-has-error",
                showNameError
              )}
            >
              <label htmlFor="releaseCandidateName">Name</label>
              <input
                className="cps-form-control"
                placeholder="Enter name"
                type="text"
                value={this.state.name}
                onChange={(event) => {
                  this.setState({
                    name: event.target.value,
                  });
                }}
              />
              {showNameError && (
                <>
                  <span
                    aria-hidden="true"
                    className="cps-icon-error cps-form-control-feedback"
                  />
                  <span className="cps-error-block">Name must be unique!</span>
                </>
              )}
            </div>
            <div className="cps-form-group">
              <label htmlFor="releaseCandidateDescription">Description</label>
              <textarea
                className="cps-form-control modal-textarea"
                placeholder="Enter description"
                value={this.state.description}
                onChange={(event) => {
                  this.setState({ description: event.target.value });
                }}
              />
            </div>
          </Scoped>
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton
            disabled={showNameError}
            btnType="primary"
            onClick={() => {
              this.props.updateRevision(
                this.state.name,
                this.state.description
              );
            }}
          >
            Done
          </CpButton>
          <CpButton
            btnType="flat"
            className="cp-ml-4"
            onClick={this.props.onModalClose}
          >
            Cancel
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    );
  }
}
