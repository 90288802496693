import React from "react";
import { CprButton, CprLoader } from "canopy-styleguide!sofe";
import styles from "./manage-team-members.styles.css";
import { inviteUser, editUser } from "./manage-team-members.resource.js";
import { successToast, warningToast } from "toast-service!sofe";
import { createDisposableModal } from "react-disposable-modal";
import { asyncStacktrace, catchSyncStacktrace } from "auto-trace";
import Cancelable from "react-disposable-decorator";

@Cancelable
export class InviteSme extends React.Component {
  state = {
    saving: false,
    role: null,
    product: null,
    email: null,
  };

  inviteUserClick = () => {
    this.setState((prevState, props) => {
      const existingUser = props.members
        ? props.members.find((member) => member.email === prevState.email)
        : "";
      existingUser
        ? this.props.cancelWhenUnmounted(
            editUser(prevState, { member: existingUser }).subscribe(
              (user) => {
                successToast(
                  `${existingUser.name} has been successfully edited!`
                );
                props.onNext({ prevState, user, existingUser });
                props.onCompleted();
              },
              asyncStacktrace((error) => {
                warningToast("Error adding user");
                error.showToast = false;
                catchSyncStacktrace(error);
                props.onCompleted();
              })
            )
          )
        : this.props.cancelWhenUnmounted(
            inviteUser(prevState).subscribe(
              (user) => {
                successToast(
                  `${prevState.email} has been successfully invited!`
                );
                props.onNext({ prevState, user, existingUser });
                props.onCompleted();
              },
              asyncStacktrace((error) => {
                warningToast("Error inviting user");
                error.showToast = false;
                catchSyncStacktrace(error);
                props.onCompleted();
              })
            )
          );
      return { saving: true };
    });
  };

  render() {
    const { saving, role, product, email } = this.state;

    const validEmail = email && email.indexOf("@canopytax.com") > -1;

    return (
      <div className="cps-modal">
        <div className="cps-modal__screen" />
        <div className="cps-modal__dialog cps-card__height-3">
          {saving ? (
            <div className={styles.loader}>
              <CprLoader />
            </div>
          ) : (
            <div>
              <div className="cps-card__header cps-subheader">
                <span>Invite a team member</span>
                <a
                  onClick={this.props.onCompleted}
                  className="cps-modal__dialog__close cps-icon cps-icon-close"
                />
              </div>
              <div className="cps-card__body">
                <div className="cps-form-horizontal">
                  <div className="cps-form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        margin: "0 16px 0 8px",
                        fontSize: "1.4rem",
                      }}
                    >
                      Email:
                    </label>
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      className="cps-form-control"
                      placeholder="Enter a Canopy Tax email here!"
                    />
                  </div>
                </div>
                <div style={{ marginTop: 24 }}>
                  <div style={{ fontWeight: "bold" }}>
                    Product they will be working on:
                  </div>
                  <div className={styles.option}>
                    <label className="cps-radio">
                      <input
                        type="radio"
                        name="product"
                        value="Tax Resolution"
                        checked={product === "Tax Resolution"}
                        onChange={(e) =>
                          this.setState({ product: e.target.value })
                        }
                      />
                      <span>Tax Resolution</span>
                    </label>
                  </div>
                  <div className={styles.option}>
                    <label className="cps-radio">
                      <input
                        type="radio"
                        name="product"
                        value="Tax Preparation"
                        checked={product === "Tax Preparation"}
                        onChange={(e) =>
                          this.setState({ product: e.target.value })
                        }
                      />
                      <span>Tax Preparation</span>
                    </label>
                  </div>
                </div>
                <div style={{ marginTop: 24 }}>
                  <div style={{ fontWeight: "bold" }}>Their role:</div>
                  <div className={styles.option}>
                    <label className="cps-radio">
                      <input
                        type="radio"
                        name="role"
                        value="senior"
                        checked={role === "senior"}
                        onChange={(e) =>
                          this.setState({ role: e.target.value })
                        }
                      />
                      <span>Senior associate</span>
                    </label>
                  </div>
                  <div className={styles.option}>
                    <label className="cps-radio">
                      <input
                        type="radio"
                        name="role"
                        value="intern"
                        checked={role === "intern"}
                        onChange={(e) =>
                          this.setState({ role: e.target.value })
                        }
                      />
                      <span>Intern</span>
                    </label>
                  </div>
                  <div className={styles.option}>
                    <label className="cps-radio">
                      <input
                        type="radio"
                        name="role"
                        value="Manager"
                        checked={role === "Manager"}
                        onChange={(e) =>
                          this.setState({ role: e.target.value })
                        }
                      />
                      <span>Manager</span>
                    </label>
                  </div>
                </div>
                <div style={{ marginTop: 24 }}>
                  <CprButton
                    onClick={this.inviteUserClick}
                    actionType="primary"
                    disabled={!role || !email || !validEmail}
                  >
                    Invite user
                  </CprButton>
                  <CprButton
                    actionType="flat"
                    style={{ marginLeft: 18 }}
                    onClick={this.props.onCompleted}
                  >
                    Cancel
                  </CprButton>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default function showInviteSmeModal(members) {
  return createDisposableModal(InviteSme, { members });
}
