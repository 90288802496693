import React from "react";
import { Scoped, k } from "kremling";
import { catchAsyncStacktrace } from "auto-trace";
import { find, map, without, sortBy } from "lodash";

import GovernmentCodeList from "./government-code-list.component";
import SubmissionTypeToggles from "./submission-type-toggles.component";
import { getGovernmentCodes } from "../versions.resource";

export default class VisibilityTogglesModal extends React.Component {
  state = {
    saving: false,
    activeGovernmentCode: "IRS",
    governmentCodes: [],
  };

  componentDidMount() {
    this.getGovernmentCodes();
  }

  getGovernmentCodes = () => {
    this.props.cancelWhenUnmounted(
      getGovernmentCodes(
        `${this.props.revision.version}-${this.props.revision.id}`
      ).subscribe((governmentCodesObj) => {
        const governmentAsArray = map(governmentCodesObj, (val, key) => ({
          code: key,
          name: val,
        }));

        const IRSElement = find(governmentAsArray, { code: "IRS" });
        const withoutFederal = without(governmentAsArray, IRSElement);

        const sortedCodes = sortBy(withoutFederal, "name");

        // Add back the IRS/federal code as the first element of the array
        sortedCodes.unshift(IRSElement);

        this.setState({
          governmentCodes: sortedCodes,
        });
      }, catchAsyncStacktrace())
    );
  };

  updateGovCode = (govCode) => {
    this.setState({ activeGovernmentCode: govCode });
  };

  render() {
    return (
      this.props.revision && (
        <Scoped css={togglesModal}>
          <div className="cps-modal__screen">
            <div className="modal-box">
              <div className="cps-card__header cps-subheader-sm">
                <div className="flex-row">
                  <span className="ellipsis-title">Visibility Toggles</span>
                  <span style={{ flex: 1 }} />
                  <a
                    onClick={this.props.onCompleted}
                    className="cps-modal__dialog__close cps-icon cps-icon-close"
                  />
                </div>
              </div>
              <div className="cps-card__body">
                <div className="cps-row">
                  <GovernmentCodeList
                    updateGovCode={this.updateGovCode}
                    governmentCodes={this.state.governmentCodes}
                    activeGovernmentCode={this.state.activeGovernmentCode}
                  />
                  <SubmissionTypeToggles
                    governmentCode={this.state.activeGovernmentCode}
                    version={`${this.props.revision.version}-${this.props.revision.id}`}
                    cancelWhenUnmounted={this.props.cancelWhenUnmounted}
                  />
                </div>
              </div>
            </div>
          </div>
        </Scoped>
      )
    );
  }
}

const togglesModal = k`
  .modal-box {
    position: absolute;
    top: 6%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 100000;
    width: fit-content;
    height: fit-content;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    box-shadow: 0 16px 50px -24px rgba(75, 78, 83, 0.4);
    border-radius: 8px 8px 8px 8px;
  }

  .cps-modal__dialog__close {
    top: 0;
  }

  .cps-row {
    display: flex;
  }

  .close-border {
    border: 1px solid #979797;
    max-width: 1px;
    min-height: 25px;
    margin: 6px;
  }

  .ellipsis-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 800px;
  }

  .flex-row {
    display: flex;
    align-items: center;
  }
`;
