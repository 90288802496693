import React from "react";
import { any, func } from "prop-types";
import { Scoped } from "kremling";
import { get, map, isEmpty, filter, find, forEach, reduce, noop } from "lodash";
import AsyncDecorator from "react-disposable-decorator";
import { syncStacktrace } from "auto-trace";
import { Link } from "react-router-dom";

import {
  isDraft,
  isLive,
  isNormalReleaseCandidate,
  isProductionRevision,
  isReleaseCandidate,
} from "sme-version!sofe";
import { CprIcon, CprLoader } from "canopy-styleguide!sofe";

import {
  createRevision,
  deleteRevision,
  getVersion,
  setRevisionActive,
} from "src/versions2/versions.resource.js";
import css from "src/versions2/versions-styles.krem.css";
import RevisionListRow from "src/versions2/revision-list-row.component.js";
import CreateReleaseCandidateModal from "src/versions2/create-release-candidate-modal.component.js";

import DiffDialog from "src/versions/diff-dialog.component.js";

@AsyncDecorator
export default class Version extends React.Component {
  static propTypes = {
    match: any,
    cancelWhenUnmounted: func,
  };
  static defaultProps = {
    cancelWhenUnmounted: noop,
  };

  state = {
    loading: false,
    creatingReleaseCandidate: false,
    diffModalOpen: false,
    draftRevisions: [],
    isHotFix: false,
    modalOpen: false,
    productionRevisions: [],
    releaseCandidateRevisions: [],
    targetRevision: null,
    version: {},
    standardRevisionExists: false,
  };

  componentDidMount() {
    this.fetchVersion();
  }

  fetchVersion = () => {
    const versionId = get(this.props, "match.params.versionId");
    this.setState({ loading: true });

    this.props.cancelWhenUnmounted(
      getVersion(versionId).subscribe(
        (version) => {
          const revisions = version.revisions || [];

          const productionRevisions = filter(revisions, (revision) =>
            isProductionRevision(revision)
          ).reverse();

          const releaseCandidateRevisions = filter(revisions, (revision) =>
            isReleaseCandidate(revision)
          ).reverse();

          let standardRevisionExists = false;
          forEach(releaseCandidateRevisions, (releaseCandidate) => {
            if (isNormalReleaseCandidate(releaseCandidate)) {
              standardRevisionExists = true;
            }
          });

          const draftRevisions = filter(revisions, (revision) =>
            isDraft(revision)
          ).reverse();

          this.setState({
            draftRevisions,
            productionRevisions,
            releaseCandidateRevisions,
            standardRevisionExists,
            version,
            loading: false,
          });
        },
        (err) => {
          this.setState({ loading: false });
          syncStacktrace(err);
        }
      )
    );
  };

  render() {
    const {
      creatingReleaseCandidate,
      diffModalOpen,
      draftRevisions,
      isHotFix,
      modalOpen,
      productionRevisions,
      releaseCandidateRevisions,
      standardRevisionExists,
      targetRevision,
      version,
    } = this.state;

    return (
      <Scoped css={css}>
        {this.state.loading && (
          <CprLoader
            page
            color="var(--cps-color-primary)"
            style={{
              position: "absolute",
              height: "100vh",
              width: "100vw",
              paddingTop: "40vh",
              backgroundColor: "rgb(0,0,0,0.1)",
            }}
          />
        )}
        <div className="cps-nav-content +tall-top +full">
          <div className="version-header">
            <div className="version-header-back-link">
              <Link to={`/sme/versions/?manage=1`}>
                <CprIcon name="arrow-line-left" /> Version Manager
              </Link>
            </div>
            <div className="version-title-label">{version.name}</div>
          </div>
          <div className="list-box">
            <div className="list-header-row">Production</div>
            {this.getListHeader("production")}
            {isEmpty(productionRevisions) ? (
              <div className="list-row">
                <i>No published versions to display.</i>
              </div>
            ) : (
              map(productionRevisions, (revision) => (
                <RevisionListRow
                  revision={{ ...revision }}
                  onSetActive={this.handleSetActive}
                  onCreateReleaseCandidateClick={
                    this.handleCreateReleaseCandidateClick
                  }
                  onDeleteReleaseCandidate={this.handleDeleteReleaseCandidate}
                  onViewChanges={this.handleViewChanges}
                  key={revision.id}
                  isNameUnique={this.isNameUnique}
                />
              ))
            )}
          </div>
          <div className="list-box">
            <div className="list-header-row">Release Candidate</div>
            {this.getListHeader("releaseCandidate")}
            {!modalOpen && creatingReleaseCandidate && (
              <div className="loader-row">
                <CprLoader />
              </div>
            )}
            {isEmpty(releaseCandidateRevisions) ? (
              <div className="list-row">
                <i>
                  {" "}
                  No release candidates have been created yet. Create a Standard
                  release candidate from a draft, or create a Hot Fix release
                  candidate from an active production.
                </i>
              </div>
            ) : (
              map(releaseCandidateRevisions, (revision) => (
                <RevisionListRow
                  revision={{ ...revision }}
                  onSetActive={this.handleSetActive}
                  onDeleteReleaseCandidate={this.handleDeleteReleaseCandidate}
                  onViewChanges={this.handleViewChanges}
                  key={revision.id}
                  isNameUnique={this.isNameUnique}
                />
              ))
            )}
          </div>
          <div className="list-box">
            <div className="list-header-row">Work in Progress</div>
            {this.getListHeader("draft")}
            {isEmpty(draftRevisions) ? (
              <div className="list-row">
                <i>No drafts currently in progress.</i>
              </div>
            ) : (
              map(draftRevisions, (revision) => (
                <RevisionListRow
                  revision={{ ...revision }}
                  onCreateReleaseCandidateClick={
                    this.handleCreateReleaseCandidateClick
                  }
                  onViewChanges={this.handleViewChanges}
                  key={revision.id}
                  standardRevisionExists={standardRevisionExists}
                  isNameUnique={this.isNameUnique}
                />
              ))
            )}
          </div>
        </div>
        {modalOpen && creatingReleaseCandidate && (
          <CreateReleaseCandidateModal
            targetRevision={targetRevision}
            onModalClose={this.handleModalClose}
            isHotFix={isHotFix}
            onCreateReleaseCandidate={this.handleCreateReleaseCandidate}
            isNameUnique={this.isNameUnique}
          />
        )}
        {diffModalOpen && (
          <DiffDialog
            version={version}
            revision={targetRevision}
            actions={{ closeDiffDialog: this.handleModalClose }}
          />
        )}
      </Scoped>
    );
  }

  handleViewChanges = (revision) => {
    this.setState({
      diffModalOpen: true,
      targetRevision: revision,
    });
  };

  handleCreateReleaseCandidateClick = (targetRevision, isHotFix) => {
    this.setState({
      modalOpen: true,
      targetRevision,
      creatingReleaseCandidate: true,
      isHotFix,
    });
  };

  handleCreateReleaseCandidate = (revision, name, description) => {
    this.setState({ modalOpen: false });
    this.props.cancelWhenUnmounted(
      createRevision(revision, name, description).subscribe((revision) => {
        this.setState({
          creatingReleaseCandidate: false,
        });
        this.fetchVersion();
      })
    );
  };

  handleSetActive = (revision) => {
    this.setState({ loading: true });
    this.props.cancelWhenUnmounted(
      setRevisionActive(revision).subscribe(
        (response) => {
          this.setState((prevState) => {
            const oldActiveRevision = find(
              prevState.productionRevisions,
              (revision) => isLive(revision)
            );

            if (oldActiveRevision) {
              oldActiveRevision.isLive = false;
              oldActiveRevision.status = "published";
            }

            const target = find(prevState.productionRevisions, {
              id: revision.id,
            });

            // we are simply setting a different production revision to be live
            if (target) {
              target.status = "live";
              target.isLive = true;

              return {
                productionRevisions: prevState.productionRevisions,
                loading: false,
              };
            } else {
              // we are taking a release candidate and going live
              this.fetchVersion();
            }
          });
        },
        (err) => {
          this.setState({ loading: false });
          syncStacktrace(err);
        }
      )
    );
  };

  isNameUnique = (name, revisionId) => {
    return (
      filter(
        get(this.state, "version.revisions"),
        (revision) => revision.id !== revisionId
      ).find((revision) => revision.name === name) === undefined
    );
  };

  handleDeleteReleaseCandidate = (revisionToDelete) => {
    this.props.cancelWhenUnmounted(
      deleteRevision(revisionToDelete).subscribe((_) => {
        this.fetchVersion();
      })
    );
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false,
      targetRevision: null,
      creatingReleaseCandidate: false,
      isHotFix: false,
      diffModalOpen: false,
    });
  };

  getListHeader = (listType) => {
    if (listType === "production") {
      return (
        <div className="list-row">
          <div className="name-column">Name</div>
          <div className="status-and-type-column">Status</div>
          <div className="date-activated-column">Date Activated</div>
          <div className="description-column">Description</div>
          <div className="actions-column" />
        </div>
      );
    } else if (listType === "releaseCandidate") {
      return (
        <div className="list-row">
          <div className="name-column">Name</div>
          <div className="status-and-type-column">Type</div>
          <div className="date-activated-column">Date Created</div>
          <div className="description-column">Description</div>
          <div className="actions-column" />
        </div>
      );
    } else {
      return (
        <div className="list-row">
          <div className="name-column">Name</div>
          <div className="actions-column" />
        </div>
      );
    }
  };
}
