import React from "react";
import { Scoped, k } from "kremling";
import { range, omit } from "lodash";
import { getVersions } from "src/versions/versions.resource.js";
import { CpSelectSingle } from "canopy-styleguide!sofe";

export default class EditVersion extends React.Component {
  state = {
    name: this.props.name || "",
    description: this.props.description || "",
    year: this.props.year || "",
    product: this.props.product || "",
    copiedFrom: this.props.version || "0",
    versions: [],
    createFromExisting: false,
    taxYears: [],
  };

  update = (name, e) =>
    this.setState({ [name]: e.target ? e.target.value : e });

  action = (e) => {
    e.preventDefault();
    this.props.onNext(omit(this.state, this.generateOmitList()));
    this.props.onCompleted();
  };

  generateOmitList = () => {
    if (!this.props.name) {
      return this.state.createFromExisting
        ? ["versions", "createFromExisting", "product", "taxYears"]
        : ["versions", "createFromExisting", "copiedFrom", "taxYears"];
    } else {
      return ["copiedFrom", "taxYears", "createFromExisting", "versions"];
    }
  };

  isSelectedYear = (year) => year === this.state.year;

  componentDidMount() {
    this.versionsOb = getVersions().subscribe((versions) =>
      this.setState({
        versions: versions.reduce(
          (acc, version) =>
            version.product === "taxRes"
              ? acc
              : acc.concat({
                  value: version.name,
                  key: version.id,
                }),
          []
        ),
        taxYears: this.taxPrepYearList(versions),
      })
    );
  }

  componentWillUnmount() {
    this.versionsOb && this.versionsOb.unsubscribe();
  }

  render() {
    const {
      name,
      description,
      year,
      product,
      copiedFrom,
      createFromExisting,
      versions,
    } = this.state;
    const valid =
      name &&
      (product === "taxRes" || year || (createFromExisting && copiedFrom));
    const copiedFromValue = versions.find((v) => v.key === copiedFrom);
    return (
      <div className="cps-modal">
        <Scoped css={css}>
          <div className="cps-modal__screen" />
          <form className="cps-modal__dialog cps-card" onSubmit={this.action}>
            <div className="cps-card__header cps-subheader-sm">
              <span>
                {this.props.name ? `Edit ${this.props.name}` : "New version"}{" "}
              </span>
              <a
                onClick={this.props.onCompleted}
                className="cps-modal__dialog__close cps-icon cps-icon-close"
              />
            </div>
            <div className="cps-card__body">
              <>
                <label className="cps-radio">
                  <input
                    name="product"
                    type="radio"
                    value="taxPrep"
                    checked={product === "taxPrep"}
                    onChange={this.update.bind(this, "product")}
                  />
                  <span style={{ fontSize: 14 }}>Tax preparation</span>
                </label>
                {!this.state.createFromExisting && (
                  <label className="cps-radio">
                    <input
                      name="product"
                      type="radio"
                      value="taxRes"
                      checked={product === "taxRes"}
                      onChange={this.update.bind(this, "product")}
                    />
                    <span style={{ fontSize: 14 }}>Tax resolution</span>
                  </label>
                )}
              </>
              {!this.props.name && product === "taxPrep" && (
                <div className="cps-margin-top-24">
                  <label className="cps-checkbox">
                    <input
                      name="useVersion"
                      type="checkbox"
                      value="useVersion"
                      checked={createFromExisting}
                      onChange={() =>
                        this.setState((prevS) => ({
                          createFromExisting: !prevS.createFromExisting,
                        }))
                      }
                    />
                    <span style={{ fontSize: 14 }}>
                      Create from existing version?
                    </span>
                  </label>
                </div>
              )}
              {(product === "taxPrep" || createFromExisting) && (
                <div className="cps-margin-top-24">
                  <label>Tax year</label>
                  <select
                    className="cps-form-control"
                    value={year}
                    onChange={this.update.bind(this, "year")}
                    style={{ width: 290 }}
                  >
                    <option key="select" value="" />
                    {this.state.taxYears.map((year) => (
                      <option
                        key={year}
                        value={`${year}`}
                        selected={this.isSelectedYear(`${year}`) || null}
                      >
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {this.state.createFromExisting && (
                <div className="cps-margin-top-24">
                  <CpSelectSingle
                    data={versions}
                    onChange={(e) => this.update("copiedFrom", e.key)}
                    placeholder="Source Version (optional)"
                    value={copiedFromValue}
                    transformData={(e) => ({ ...e, id: e.key, name: e.value })}
                    triggerIsBlock
                  />
                </div>
              )}
              <div className="cps-margin-top-24">
                <label>Name of version</label>
                <input
                  value={name}
                  onChange={this.update.bind(this, "name")}
                  type="text"
                  className="cps-form-control"
                  placeholder="Add version name"
                />
              </div>
              <div className="cps-margin-top-24">
                <label>Version description</label>
                <textarea
                  placeholder="Add version description"
                  value={description}
                  onChange={this.update.bind(this, "description")}
                  className="cps-form-control"
                />
              </div>
            </div>
            <div className="cps-modal__dialog__actions">
              <input
                type="submit"
                className={`cps-btn +primary ${valid ? "" : "+disabled"}`}
                value={"Save version"}
              />
              <a onClick={this.props.onCompleted} className="cps-link">
                Cancel
              </a>
            </div>
          </form>
        </Scoped>
      </div>
    );
  }
  taxPrepYearList = (versions) => {
    const existingVersionYears = versions.reduce(
      (acc, version) =>
        version.product === "taxPrep"
          ? acc.concat(parseInt(version.year, 10))
          : acc,
      []
    );
    return range(2016, 2030).filter(
      (year) =>
        !~existingVersionYears.indexOf(year) || this.isSelectedYear(`${year}`)
    );
  };
}

const css = k`
  .cps-form-control::placeholder {
    color: #AFAFAF;
    font-style: italic;
  }
`;
