import React from "react";
import { from } from "rxjs";
import { CprLoader } from "canopy-styleguide!sofe";
import Cancelable from "react-disposable-decorator";

export default function Load(prom) {
  return Cancelable(
    class Component extends React.Component {
      state = {
        mod: null,
      };
      componentDidMount() {
        this.props.cancelWhenUnmounted(
          from(prom().then((mod) => mod.default)).subscribe((mod) => {
            this.setState({ mod });
          })
        );
      }

      render() {
        return this.state.mod ? (
          <this.state.mod {...this.props} />
        ) : (
          <CprLoader />
        );
      }
    }
  );
}
